import { Component, ElementRef, ViewContainerRef, ChangeDetectionStrategy, QueryList, Renderer2, Injector, ValueProvider } from '@angular/core';
import { ComponentBase, IComponentBase, applyMixins, ComponentMixins, PropertyCollectionInfo, setValue } from '@syncfusion/ej2-angular-base';
import { RecurrenceEditor } from '@syncfusion/ej2-schedule';



export const /** @type {?} */ inputs: string[] = ['calendarMode','cssClass','dateFormat','enablePersistence','enableRtl','firstDayOfWeek','frequencies','locale','maxDate','minDate','selectedType','startDate','value'];
export const /** @type {?} */ outputs: string[] = ['change'];
export const /** @type {?} */ twoWays: string[] = [];
/**
 * `ejs-recurrenceeditor` represents the Angular RecurrenceEditor Component.
 * ```html
 * <ejs-recurrenceeditor></ejs-recurrenceeditor>
 * ```
 */
@ComponentMixins([ComponentBase])
export class RecurrenceEditorComponent extends RecurrenceEditor implements IComponentBase {
public context : any;
public tagObjects: any;
public change: any;
/**
 * @param {?} ngEle
 * @param {?} srenderer
 * @param {?} viewContainerRef
 * @param {?} injector
 */
constructor(private ngEle: ElementRef,
private srenderer: Renderer2,
private viewContainerRef:ViewContainerRef,
private injector: Injector) {
        super();
        this.element = this.ngEle.nativeElement;
        this.injectedModules = this.injectedModules || [];

        this.registerEvents(outputs);
        this.addTwoWay.call(this, twoWays);
        setValue('currentInstance', this, this.viewContainerRef);
        this.context  = new ComponentBase();
    }
/**
 * @return {?}
 */
public ngOnInit() {
        this.context.ngOnInit(this);
    }
/**
 * @return {?}
 */
public ngAfterViewInit(): void {
        this.context.ngAfterViewInit(this);
    }
/**
 * @return {?}
 */
public ngOnDestroy(): void {
        this.context.ngOnDestroy(this);
    }
/**
 * @return {?}
 */
public ngAfterContentChecked(): void {
        
        this.context.ngAfterContentChecked(this);
    }
public registerEvents: (eventList: string[]) => void;
public addTwoWay: (propList: string[]) => void;
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
    selector: 'ejs-recurrenceeditor',
    inputs: inputs,
    outputs: outputs,
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
    queries: {

    }
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ElementRef, },
{type: Renderer2, },
{type: ViewContainerRef, },
{type: Injector, },
];
}

function RecurrenceEditorComponent_tsickle_Closure_declarations() {
/** @type {?} */
RecurrenceEditorComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
RecurrenceEditorComponent.ctorParameters;
/** @type {?} */
RecurrenceEditorComponent.prototype.context;
/** @type {?} */
RecurrenceEditorComponent.prototype.tagObjects;
/** @type {?} */
RecurrenceEditorComponent.prototype.change;
/** @type {?} */
RecurrenceEditorComponent.prototype.registerEvents;
/** @type {?} */
RecurrenceEditorComponent.prototype.addTwoWay;
/** @type {?} */
RecurrenceEditorComponent.prototype.ngEle;
/** @type {?} */
RecurrenceEditorComponent.prototype.srenderer;
/** @type {?} */
RecurrenceEditorComponent.prototype.viewContainerRef;
/** @type {?} */
RecurrenceEditorComponent.prototype.injector;
}



interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
