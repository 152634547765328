"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var click_outside_directive_1 = require("./click-outside.directive");
var ClickOutsideModule = /** @class */ (function () {
    function ClickOutsideModule() {
    }
    ClickOutsideModule.decorators = [
        { type: core_1.NgModule, args: [{
                    declarations: [click_outside_directive_1.ClickOutsideDirective],
                    exports: [click_outside_directive_1.ClickOutsideDirective]
                },] },
    ];
    /** @nocollapse */
    ClickOutsideModule.ctorParameters = function () { return []; };
    return ClickOutsideModule;
}());
exports.ClickOutsideModule = ClickOutsideModule;
